import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "flowbite";
import $ from 'jquery/src/jquery';
import debounce from 'lodash/debounce';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function() {
  initializeFlowbite();
  setupSearchFormSubmission();

  if (isSafari()) {
    console.log("Safari detected: Using Safari-specific logic");
    setupArticleVisitTrackingSafari();
  } else {
    console.log("Non-Safari browser detected: Using standard logic");
    setupArticleVisitTracking();
  }

  setupAdClickTracking();
  scrollToHighlightedNews();
  setupNewsletterSubscription();
  setupNewsletterPopUpModal();
});

function detectBrowser() {
  try {
    const userAgent = navigator.userAgent.toLowerCase();

    if (!userAgent) {
      return "Unknown"; // Handle cases where the user agent is missing
    }

    // Browser mapping based on keywords in userAgent
    const browserMap = {
      safari: "Safari",
      chrome: "Chrome",
      firefox: "Firefox",
      edg: "Edge",
      opr: "Opera",
      opera: "Opera",
      brave: "Brave",
      msie: "Internet Explorer",
      trident: "Internet Explorer"
    };

    for (const key in browserMap) {
      if (key === "safari" && userAgent.includes("chrome")) {
        // Skip Safari detection if "chrome" is in the user agent.
        // This prevents misidentifying Chrome as Safari because Chrome’s user agent also contains "Safari".
        // By skipping this check, Chrome will be correctly detected later in the loop.
        continue;
      }

      if (userAgent.includes(browserMap[key].toLowerCase())) {
        return browserMap[key]; // Return the detected browser
      }
    }

    return "Unknown"; // Default case if no browser matches
  } catch (error) {
    console.error("Error detecting browser:", error);
    return "Unknown";
  }
}

function isSafari() {
  return detectBrowser() === "Safari";
}

function isChrome() {
  return detectBrowser() === "Chrome";
}

function isFirefox() {
  return detectBrowser() === "Firefox";
}

function isEdge() {
  return detectBrowser() === "Edge";
}

function isOpera() {
  return detectBrowser() === "Opera";
}

function isBrave() {
  return detectBrowser() === "Brave";
}

function isIE() {
  return detectBrowser() === "Internet Explorer";
}

// Global variables to track reCAPTCHA status
window.hasSubscribeNewsletterFormRecaptchaPassed = false;
window.hasSubscribeNewsletterModalFormRecaptchaPassed = false;

function toggleSubmitButton(didCheckPass, submitButton, form="") {
  if (!submitButton) return;

  let canSubmit;

  if (form == "subscribe_newsletter_form") {
    const weeklyCheckbox = document.querySelector("#subscribe_weekly_newsletter");
    const dailyCheckbox = document.querySelector("#subscribe_daily_newsletter");

    if (!weeklyCheckbox || !dailyCheckbox) return;

    const isAnyChecked = weeklyCheckbox.checked || dailyCheckbox.checked;
    canSubmit = isAnyChecked && didCheckPass;
  } else if (form == "subscribe_newsletter_modal_form") {
    const weeklyCheckbox = document.querySelector("#subscribe_weekly_newsletter_modal_checkbox");
    const dailyCheckbox = document.querySelector("#subscribe_daily_newsletter_modal_checkbox");

    if (!weeklyCheckbox || !dailyCheckbox) return;

    const isAnyChecked = weeklyCheckbox.checked || dailyCheckbox.checked;
    canSubmit = isAnyChecked && didCheckPass;
  } else {
    canSubmit = didCheckPass;
  }

  submitButton.disabled = !canSubmit;
  submitButton.classList.toggle("bg-gray-400", !canSubmit);
  submitButton.classList.toggle("cursor-not-allowed", !canSubmit);
  submitButton.classList.toggle("bg-[#ed2c34]", canSubmit);
  submitButton.classList.toggle("hover:bg-[#e12d2b]", canSubmit);
  submitButton.classList.toggle("cursor-pointer", canSubmit);
}

function enableContactUsFormSubmitButton() {
  const submitButton = document.getElementById('contact_form_submit_btn');
  toggleSubmitButton(true, submitButton);
}

function disableContactUsFormSubmitButton() {
  const submitButton = document.getElementById('contact_form_submit_btn');
  toggleSubmitButton(false, submitButton);
}

function enableSubscribeNewsletterModalSubmitButton() {
  const submitButton = document.getElementById('subscribe_newsletter_modal_btn');
  window.hasSubscribeNewsletterModalFormRecaptchaPassed = true;

  toggleSubmitButton(true, submitButton, "subscribe_newsletter_modal_form");
}

function disableSubscribeNewsletterModalSubmitButton() {
  const submitButton = document.getElementById('subscribe_newsletter_modal_btn');
  window.hasSubscribeNewsletterModalFormRecaptchaPassed = false;

  toggleSubmitButton(false, submitButton, "subscribe_newsletter_modal_form");
}

function enableNewsletterFormSubmitButton() {
  const submitButton = document.getElementById('subscribe_newsletter_form_submit_btn');
  window.hasSubscribeNewsletterFormRecaptchaPassed = true;

  toggleSubmitButton(true, submitButton, "subscribe_newsletter_form");
}

function disableNewsletterFormSubmitButton() {
  const submitButton = document.getElementById('subscribe_newsletter_form_submit_btn');
  window.hasSubscribeNewsletterFormRecaptchaPassed = false;

  toggleSubmitButton(false, submitButton, "subscribe_newsletter_form");
}

function enableNewsTipFormSubmitButton() {
  const submitButton = document.getElementById('newstip_form_submit_btn');
  toggleSubmitButton(true, submitButton);
}

function disableNewsTipFormSubmitButton() {
  const submitButton = document.getElementById('newstip_form_submit_btn');
  toggleSubmitButton(false, submitButton);
}

// Make functions globally available to reCAPTCHA
window.enableContactUsFormSubmitButtonWrapper = enableContactUsFormSubmitButton;
window.disableContactUsFormSubmitButtonWrapper = disableContactUsFormSubmitButton;

window.enableSubscribeNewsletterModalSubmitButtonWrapper = enableSubscribeNewsletterModalSubmitButton;
window.disableSubscribeNewsletterModalSubmitButtonWrapper = disableSubscribeNewsletterModalSubmitButton;

window.enableNewsletterFormSubmitButtonWrapper = enableNewsletterFormSubmitButton;
window.disableNewsletterFormSubmitButtonWrapper = disableNewsletterFormSubmitButton;

window.enableNewsTipFormSubmitButtonWrapper = enableNewsTipFormSubmitButton;
window.disableNewsTipFormSubmitButtonWrapper = disableNewsTipFormSubmitButton;

// Function to initialize Flowbite
function initializeFlowbite() {
  if (typeof window.initFlowbite === "function") {
    window.initFlowbite();
    console.log("Flowbite initialized successfully.");
  } else {
    console.error("Flowbite initialization function is missing.");
  }
}

// Function to handle search form submission
function setupSearchFormSubmission() {
  const searchInput = $('#default-search');
  // Prevent duplicate event listeners using `off().on()`

  searchInput.off('change').on('change', function () {
    $(this).closest('form').trigger('submit.rails');
  });

  searchInput.off('input').on('input', debounce(function () {
    if ($(this).val() === '') {
      $(this).closest('form').trigger('submit.rails');
    }
  }, 300));
}

// Function to handle article visit tracking, for non-Safari browsers only
function setupArticleVisitTracking() {
  // Prevent duplicate event listeners using `off().on()`
  $(document).off('click', 'a[data-article-id]').on('click', 'a[data-article-id]', function (event) {
    event.preventDefault();

    const articleId = $(this).data('article-id');
    const url = $(this).attr('href');

    $.ajax({
      url: `/articles/${articleId}/increment_visit_count`,
      type: 'POST',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function () {
        window.open(url, '_blank'); // Redirect after incrementing clicks
      },
      error: function (xhr) {
        console.error('Error:', xhr.responseText);
      }
    });
  });
}

// Function to handle article visit tracking, for Safari
function setupArticleVisitTrackingSafari() {
  // Prevent duplicate event listeners using `off().on()`
  $(document).off('click', 'a[data-article-id]').on('click', 'a[data-article-id]', function (event) {
    event.preventDefault();

    const articleId = $(this).data('article-id');
    const url = $(this).attr('href');

    // **Safari Workaround**: Open the link first before sending the tracking request.
    // Safari blocks `window.open()` if it's delayed by an asynchronous action (like an AJAX call).
    window.open(url, '_blank');

    // Send the tracking request after opening the link.
    // TODO We may replace this with `navigator.sendBeacon()` later for better reliability.
    $.ajax({
      url: `/articles/${articleId}/increment_visit_count`,
      type: 'POST',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function () {
        // window.open(url, '_blank'); // Redirect after incrementing clicks
      },
      error: function (xhr) {
        console.error('Error:', xhr.responseText);
        // TODO Possible Future Improvement: Retry later using `localStorage` or `sendBeacon()`
      }
    });
  });
}

// Function to handle advertisement click tracking
function setupAdClickTracking() {
  document.querySelectorAll('.carousel-image-link').forEach(link => {
    // Prevent duplicate event listeners using `removeEventListener`
    link.removeEventListener('click', handleAdClick);
    link.addEventListener('click', handleAdClick);
  });
}

function handleAdClick(event) {
  event.preventDefault();
  const imageId = this.dataset.id;

  fetch(`/ad_images/${imageId}/increment_clicks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
    credentials: 'same-origin'
  })
  .then(response => response.json())
  .then(data => {
    if (data.success) {
      console.log(`Click count for image ${imageId}: ${data.clicks_count}`);
      window.open(this.href, '_blank', 'noopener,noreferrer');
    }
  })
  .catch(error => console.error('Error:', error));
}

// Function to scroll to highlighted news on page load
function scrollToHighlightedNews() {
  // Scrolling smoothly to the highlighted article on page load
  const highlightedElements = document.querySelectorAll(".highlighted-news");

  if (highlightedElements.length > 0) {
    highlightedElements[0].scrollIntoView({ behavior: "smooth", block: "center" });
  }
}

function toggleButtonState(weeklyCheckbox, dailyCheckbox, subscribeButton, form="") {
  if (!weeklyCheckbox || !dailyCheckbox || !subscribeButton) return;

  const isAnyChecked = weeklyCheckbox.checked || dailyCheckbox.checked;

  let canSubmit;

  if (form == "subscribe_newsletter_form") {
    const hasRecaptchaPassed = window.hasSubscribeNewsletterFormRecaptchaPassed;
    canSubmit = isAnyChecked && hasRecaptchaPassed;
  } else if (form == "subscribe_newsletter_modal_form") {
    const hasRecaptchaPassed = window.hasSubscribeNewsletterModalFormRecaptchaPassed;
    canSubmit = isAnyChecked && hasRecaptchaPassed;
  } else {
    canSubmit = isAnyChecked;
  }

  subscribeButton.disabled = !canSubmit;
  subscribeButton.classList.toggle("bg-gray-400", !canSubmit);
  subscribeButton.classList.toggle("cursor-not-allowed", !canSubmit);
  subscribeButton.classList.toggle("bg-[#ed2c34]", canSubmit);
  subscribeButton.classList.toggle("hover:bg-[#e12d2b]", canSubmit);
  subscribeButton.classList.toggle("cursor-pointer", canSubmit);
}

// Named functions for event listeners
function handleNewsletterCheckboxChange() {
  const weeklyCheckbox = document.querySelector("#subscribe_weekly_newsletter");
  const dailyCheckbox = document.querySelector("#subscribe_daily_newsletter");
  const subscribeButton = document.querySelector("#subscribe_newsletter_form_submit_btn");
  toggleButtonState(weeklyCheckbox, dailyCheckbox, subscribeButton, "subscribe_newsletter_form");
}

// Function to handle newsletter subscription toggle
function setupNewsletterSubscription() {
  const weeklyCheckbox = document.querySelector("#subscribe_weekly_newsletter");
  const dailyCheckbox = document.querySelector("#subscribe_daily_newsletter");
  const subscribeButton = document.querySelector("#subscribe_newsletter_form_submit_btn");

  if (!weeklyCheckbox || !dailyCheckbox || !subscribeButton) return;

  // Remove old event listeners using named function
  weeklyCheckbox.removeEventListener("change", handleNewsletterCheckboxChange);
  dailyCheckbox.removeEventListener("change", handleNewsletterCheckboxChange);

  // Add new event listeners
  weeklyCheckbox.addEventListener("change", handleNewsletterCheckboxChange);
  dailyCheckbox.addEventListener("change", handleNewsletterCheckboxChange);

  // Initial check on page load
  toggleButtonState(weeklyCheckbox, dailyCheckbox, subscribeButton, "subscribe_newsletter_form");
}

// Named functions for event listeners
function handleNewsletterModalCheckboxChange() {
  const weeklyCheckbox = document.querySelector("#subscribe_weekly_newsletter_modal_checkbox");
  const dailyCheckbox = document.querySelector("#subscribe_daily_newsletter_modal_checkbox");
  const subscribeButton = document.querySelector("#subscribe_newsletter_modal_btn");
  toggleButtonState(weeklyCheckbox, dailyCheckbox, subscribeButton, "subscribe_newsletter_modal_form");
}

function setupNewsletterSubscriptionInPopUpModal() {
  const weeklyModalCheckbox = document.querySelector("#subscribe_weekly_newsletter_modal_checkbox");
  const dailyModalCheckbox = document.querySelector("#subscribe_daily_newsletter_modal_checkbox");
  const subscribeModalButton = document.querySelector("#subscribe_newsletter_modal_btn");

  if (!weeklyModalCheckbox || !dailyModalCheckbox || !subscribeModalButton) return;

  // Prevent duplicate event listeners
  weeklyModalCheckbox.removeEventListener("change", handleNewsletterModalCheckboxChange);
  dailyModalCheckbox.removeEventListener("change", handleNewsletterModalCheckboxChange);

  weeklyModalCheckbox.addEventListener("change", handleNewsletterModalCheckboxChange);
  dailyModalCheckbox.addEventListener("change", handleNewsletterModalCheckboxChange);

  // Initial check on page load
  toggleButtonState(weeklyModalCheckbox, dailyModalCheckbox, subscribeModalButton, "subscribe_newsletter_modal_form");
}

// Display newsletter subscription pop-up modal
function setupNewsletterPopUpModal() {
  const newsletterPopUpModal = document.getElementById('newsletter-popup');
  const closeModalButton = document.getElementById('close-modal');

  if (!newsletterPopUpModal || !closeModalButton) return;

  const isModalClosed = localStorage.getItem('modalClosed') === 'true';

  function showModalOnScroll() {
    if (window.scrollY / (document.body.scrollHeight - window.innerHeight) * 100 > 50) {
      newsletterPopUpModal.classList.remove('hidden');
      window.removeEventListener('scroll', showModalOnScroll);
    }
  }

  if (!isModalClosed) {
    window.addEventListener('scroll', showModalOnScroll);
  }

  closeModalButton.addEventListener('click', function() {
    newsletterPopUpModal.classList.add('hidden');
    localStorage.setItem('modalClosed', 'true');
  });

  const form = document.getElementById('newsletter-popup-form');

  if (form) {
    form.addEventListener('submit', function() {
      localStorage.setItem('modalClosed', 'true');
    });
  }

  // Initialize newsletter subscription logic separately
  setupNewsletterSubscriptionInPopUpModal();
}
